export const blockedTerms = [
  "tempmail",
  "kpopfire",
  "iblogger",
  "catchyfree",
  "xmail",
  "hyper",
  "cipher",
  "ghost",
  "cloak",
  "prime",
  "labsemail",
  "brief",
  "point",
  "network",
  "alpha",
  "bravo",
  "charlie",
  "delta",
  "echo",
  "foxtrot",
  "golf",
  "hotel",
  "india",
  "juliet",
  "kilo",
  "lima",
  "mike",
  "november",
  "oscar",
  "papa",
  "quebec",
  "romeo",
  "sierra",
  "tango",
  "uniform",
  "victor",
  "whiskey",
  "xray",
  "yankee",
  "zulu",
  "busykitten",
  "glossycandy",
  "simplelogin",
];

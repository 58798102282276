import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

function InfoPage({
  type,
  title,
  message,
  buttonText,
  uploaded,
  queue,
  icon,
  action,
  image,
}) {
  const { t } = useTranslation();
  const Icon = icon;
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Box>{icon && <Icon color="primary" size="large" />}</Box>

      <Typography variant="h5" color="primary">
        {type !== "uploading"
          ? title ?? "Hey!"
          : queue === 1
          ? t("upload.uploadingOne")
          : t("upload.uploading", { files: queue })}
      </Typography>

      <Typography
        variant="body1"
        color="initial"
        align="center"
        sx={{ color: "rgba(0,0,0,.6)", width: "60rem" }}
      >
        {type === "error" && message}
        {type === "uploading" && message}
        {type === "working" && message}
      </Typography>

      {buttonText && action && (
        <Button
          variant="contained"
          size="large"
          onClick={() => action((prev) => !prev)}
        >
          {buttonText}
        </Button>
      )}

      {type === "working" && image && (
        <Box
          sx={{
            width: "15rem",
            aspectRatio: "16 / 9",
            backgroundColor: "transparent",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: "1rem",
          }}
        />
      )}

      {(type === "uploading" || type === "working") && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ClipLoader color="rgba(0,0,0,.6)" size={20} />
        </Box>
      )}
    </Box>
  );
}

export default InfoPage;

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ModalItem from "./modalItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState, useEffect, useCallback } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExportOption from "./ExportOption";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { getFormat } from "../exportFormats";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const Download = ({ supabase, session, albumIds }) => {
  const { t } = useTranslation();
  const [format, setFormat] = useState("default");
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [albumData, setAlbumData] = useState(null);
  const [albumName, setAlbumName] = useState("");
  const [albumLoading, setAlbumLoading] = useState(true);

  const platformNames = {
    shopify: "Shopify",
    woocommerce: "WooCommerce",
    bigcommerce: "BigCommerce",
    wix: "Wix",
    squarespace: "Squarespace",
  };

  const platformLinks = {
    shopify:
      "https://help.shopify.com/manual/products/import-export/import-products",
    woocommerce:
      "https://woocommerce.com/document/product-csv-importer-exporter/#import",
    bigcommerce:
      "https://support.bigcommerce.com/s/article/Editing-Existing-Products-via-Import",
    wix: "https://support.wix.com/article/wix-stores-importing-products-to-wix-stores",
    squarespace:
      "https://support.squarespace.com/hc/articles/115000378108-Importing-products-from-a-csv",
  };

  useEffect(() => {
    const lastFormat = localStorage.getItem("lastFormat");
    if (lastFormat) {
      setFormat(lastFormat);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("lastFormat", format);
  }, [format]);

  const handleDropdownChange = (index, value) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handleChange = (event) => {
    setFormat(event.target.value);
  };

  function filtrarObjetoConOptions(objeto) {
    const resultado = [];

    for (const clave in objeto) {
      if (
        objeto.hasOwnProperty(clave) &&
        objeto[clave].hasOwnProperty("options")
      ) {
        resultado.push({
          name: clave,
          ...objeto[clave],
        });
      }
    }
    return resultado;
  }

  function applyFormatToArray(arr, formatObj) {
    for (let i = 0; i < arr.length; i++) {
      for (let prop in arr[i]) {
        if (typeof arr[i][prop] === "string") {
          arr[i][prop] = arr[i][prop].replace(/\\'/g, "'").replace(/"/g, "'");
        } else if (Array.isArray(arr[i][prop])) {
          for (let j = arr[i][prop].length - 1; j >= 0; j--) {
            if (arr[i][prop][j].length === 0) {
              arr[i][prop].splice(j, 1);
            } else {
              arr[i][prop][j] = arr[i][prop][j]
                .replace(/\\'/g, "'")
                .replace(/^,|,$/g, "");
            }
          }
        }
      }
    }

    const formattedArr = arr.map((obj) => {
      const formattedObj = {};
      Object.keys(formatObj).forEach((key) => {
        formattedObj[key] = obj.hasOwnProperty(formatObj[key]?.value)
          ? obj[formatObj[key]?.value]
          : formatObj[key]?.value?.trim();
      });
      return formattedObj;
    });
    return formattedArr;
  }

  const arrayToCsv = function (data, semicolon) {
    let separator = semicolon ? ";" : ",";
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(separator));

    for (const row of data) {
      const values = headers.map((header) => {
        const val = row[header];
        return semicolon ? val : `"${val}"`;
      });
      csvRows.push(values.join(separator));
    }
    return csvRows.join("\n");
  };

  function descargarCSV(csv, nombreArchivo) {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const enlace = document.createElement("a");
    enlace.href = url;
    enlace.download = nombreArchivo;
    enlace.hidden = true;
    document.body.appendChild(enlace);
    enlace.click();
    document.body.removeChild(enlace);
    URL.revokeObjectURL(url);
  }

  useEffect(() => {
    const getAlbumData = async () => {
      try {
        const {
          data: { session: supabaseSession },
        } = await supabase.auth.getSession();
        const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
        const exportName = `multi_export_${timestamp}`;
        let allCsvs = [];
        for (const albumId of albumIds) {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/export`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${supabaseSession.access_token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                album_id: albumId,
                format: format ? format : "default",
              }),
            }
          );
          let thisJson = await response.json();
          if (albumIds.length === 1) {
            return thisJson;
          }
          allCsvs = [...allCsvs, ...thisJson?.csv];
          await new Promise((resolve) => setTimeout(resolve, 200));
        }
        return { csv: allCsvs, name: exportName };
      } catch (err) {
        return { csv: null, name: null };
      }
    };

    if (!albumData) {
      getAlbumData().then(({ csv, name }) => {
        setAlbumData(csv);
        setAlbumName(name);
        setAlbumLoading(false);
      });
    }
  }, [albumData, albumIds, format, session, supabase]);

  const handleExport = useCallback(async () => {
    if (albumData) {
      const defaultFormat = getFormat("default", selectedValues);
      const selectedFormat = getFormat(format, selectedValues);
      descargarCSV(
        arrayToCsv(
          applyFormatToArray(
            albumData,
            selectedFormat?.fields || defaultFormat?.fields
          ),
          selectedFormat?.options?.semicolon || false
        ),
        `album_${albumName}_${format}`
      );
    }
  }, [albumData, albumName, format, selectedValues]);

  useEffect(() => {
    const selectedFormat = getFormat(format, selectedValues);
    const getOptions = filtrarObjetoConOptions(selectedFormat?.fields);
    getOptions.forEach((option, index) => {
      handleDropdownChange(index, option?.options[0]?.value);
    });
    setOptions(getOptions);
  }, [format]);

  useEffect(() => {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      setIsMobile(true);
    }
  }, [t]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "100%", lg: "25rem" },
        height: "25rem",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          mb: 8,
        }}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{
            color: "#FCFCFC",
          }}
        >
          {t("export.export")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <ModalItem
          title={t("export.settings")}
          primaryContent={t("export.format")}
          dark={true}
        >
          <FormControl
            sx={{
              m: 1,
              minWidth: 120,
              backgroundColor: "#2E0809",
              borderRadius: "2rem",
              "&:hover": {
                border: "none !important",
              },
            }}
            size="large"
          >
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={format}
              onChange={handleChange}
              variant="outlined"
              disabled={isMobile}
              sx={{
                color: "#1a72f5",
                fontWeight: "bold",
                outline: "none !important",
                fontSize: "0.89rem",
                "&:hover": {
                  border: "none",
                },
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                width: "10rem",
              }}
            >
              <MenuItem value={"default"} selected>
                Default
              </MenuItem>
              <MenuItem value={"shopify"}>Shopify</MenuItem>
              <MenuItem value={"woocommerce"}>WooCommerce</MenuItem>
              <MenuItem value={"bigcommerce"}>BigCommerce</MenuItem>
              <MenuItem value={"wix"}>Wix</MenuItem>
              <MenuItem value={"squarespace"}>Squarespace</MenuItem>
            </Select>
          </FormControl>
        </ModalItem>

        {options.length !== 0 &&
          options.map((field, index) => (
            <ModalItem
              primaryContent={field?.name.replace(/\b\w/g, (c) =>
                c.toUpperCase()
              )}
              dark={true}
              key={index}
            >
              <ExportOption
                selectedValues={selectedValues}
                index={index}
                options={options}
                field={field}
                handleDropdownChange={handleDropdownChange}
              />
            </ModalItem>
          ))}
        <Box sx={{ height: "1rem" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "sticky",
            bottom: "1rem",
            zIndex: 5,
            boxShadow:
              "inset  0px 1.5rem 0px 2rem #121216, 0px 1.5rem 2rem 3rem #121216",
            mt: "auto",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            endIcon={
              albumLoading ? (
                <CircularProgress size={20} />
              ) : (
                <FileDownloadIcon />
              )
            }
            sx={{
              fontWeight: "bold",
            }}
            onClick={() => handleExport()}
            disabled={albumLoading || isMobile}
          >
            {!albumLoading ? t("export.confirm") : ""}
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mt: 2,
              height: "24px",
            }}
          >
            {format && format !== "default" && (
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#1a72f5",
                  cursor: "pointer",
                  textDecoration: "underline",
                  "&:hover": {
                    color: "#4d9ff5",
                  },
                }}
                onClick={() => {
                  const selectedLink = platformLinks[format];
                  if (selectedLink) {
                    window.open(selectedLink, "_blank");
                  }
                }}
              >
                {t("export.instructions", { name: platformNames[format] })}
                <OpenInNewIcon sx={{ fontSize: "small", marginLeft: "5px" }} />
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Download;
